import linkedinIcon from "../assets/linkedin.png"
import githubIcon from "../assets/github.png"
import whatsappIcon from "../assets/whatsapp.png"
import envelopekIcon from "../assets/circle-envelope.png"
import behanceIcon from "../assets/behance.png"

const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
      <a 
        href={"https://www.linkedin.com/in/gabivega/"} 
        className="hover:opacity-50 transition duration-500"
        target=":blank"
        rel="noreferrer"
      >
        <img src={linkedinIcon} className="w-[30px} h-[30px]" alt="" />
      </a>
      <a 
        href={"https://api.whatsapp.com/send?phone=+54%209%203424873742&text=Hi!%20i%20want%20to%20get%20in%20touch%20with%20you%20about%20programming%20services!"} 
        className="hover:opacity-50 transition duration-500"
        target=":blank"
        rel="noreferrer"
      >
        <img src={whatsappIcon} className="w-[30px} h-[30px]" alt="" />
      </a>
      <a 
        href={"https://www.behance.net/gabivegatech"} 
        className="hover:opacity-50 transition duration-500 h-[30px] w-[30px]"
        target=":blank"
        rel="noreferrer"
      >
        <img src={behanceIcon} className="w-[30px} h-[30px]" alt="" />
      </a>
      <a 
        href={"https://github.com/gabivega"} 
        className="hover:opacity-50 transition duration-500"
        target=":blank"
        rel="noreferrer"
      >
        <img className="w-[30px] h-[30px]" src={githubIcon} alt="" />
      </a>
      <a 
        href={"mailto:contacto@gabivega.tech"} 
        className="hover:opacity-50 transition duration-500"
        target=":blank"
        rel="noreferrer"
      >
        <img className="w-[30px} h-[30px]" src={envelopekIcon} alt="" />
      </a>
   
    </div>
  )
} 

export default SocialMediaIcons