import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import project1 from "../assets/project-1.JPG";
import project2 from "../assets/huki.jpg";
import project6 from "../assets/divershines.JPG";
import project3 from "../assets/avseguros.jpg";
import project4 from "../assets/POS.JPG";
import project7 from "../assets/ferrecentro.JPG";
import project5 from "../assets/proyecto-supermercados.JPG";

const projects = [
  {},
  {
    title: "Divershines",
    description:
      "Wordpress Website for an autism platform, made wit Elementor. Includes Chatbot, and affiliate marketing store front",
    image: project6,
    link: "https://divershines.com/",
  },
  {
    title: "POS SYSTEM",
    description:
      "POS and stock system. made from scratch. Node Js, Express, MongoDb and React. Deployed on render. Project still on development, currently used on my hardware store business",
    image: project4,
    link: "https://pos-front.onrender.com/",
  },
  {
    title: "Ferrecentro",
    description:
      "Hardware Store website. Created with Wordpress + Elementor + Woocommerce",
    image: project7,
    link: "https://ferrecentrorafaela.com.ar",
  },
  {
    title: "Inflation Tracker",
    description:
      "Scrapping tool that takes the prices from my city supermarket website and calculates variations. MERN with puppeteer. Deploying still in process on AWS.",
    image: project5,
    link: "",
  },
  {
    title: "Huki Prints",
    description:
      "Professional Website with integrated Ecommerce. Created With Wordpress and Woocommerce",
    image: project2,
    link: "https://hukiprints.com/",
  },
  {
    title: "AV Seguros",
    description: "Wordpress Site created with Elementor",
    image: project3,
    link: "https://anitaseguros.ar",
  },
  {
    title: "Social Media App",
    description:
      "Fullstack Project. Social Media App with full MERN Functions. State managed wih React-Redux.(project in process)",
    image: project1,
    link: "https://redsocial-frontend.onrender.com/",
  },
  //   {title:"Raffle Generator",
  //   description: "The first app I've made 100% by my own as begginer. Create raffle tickets and picks a random winner when the countdown ends. Pure Vainalla JS, HTML, CSS",
  //   image :project6,
  //   link: "https://raffle.gabivega.tech/"
  // },
  // {title:"React CRUD app",
  // description: "Mini React app with CRUD functionalities",
  // image :project6,
  // link: "https://crudapp.gabivega.tech"
  // },
];

const container = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.2 },
  },
};
const projectVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const Project = ({ title }) => {
  const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
  bg-grey z-30 flex flex-col justify-center items-center text-center p-4 text-deep-blue`;
  const projectNumber = title.replace("Project ", "");
  return (
    <motion.div variants={projectVariant} className="relative">
      <a href={projects[projectNumber].link} target="_blank" rel="noreferrer">
        <div className={overlayStyles}>
          <p className="text-2xl font-playfair">
            {projects[projectNumber].title}
          </p>
          <p className="mt-7">
            <p className="text-sm">{projects[projectNumber].description}</p>
          </p>
        </div>
        <div className="max-w-[400px] max-h-[400px]">
          <img
            class="aspect-square object-cover"
            src={projects[projectNumber].image}
            alt={title}
          />
        </div>
      </a>
    </motion.div>
  );
};

const Projects = () => {
  return (
    <section id="projects" className=" my-16 md:my-32 ">
      {/* HEADIGNS */}
      <motion.div
        className="md:w-2/4 mx-auto text-start"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-red">PRO</span>JECTS
          </p>
          <div className="flex justify-start mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>
      </motion.div>

      {/* PROJECTS */}
      <div className="flex justify-center mt-5">
        <motion.div
          className="sm:grid sm:grid-cols-3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={container}
        >
          {/* ROW 1 */}
          <div
            className="flex justify-center text-center items-center p-10 bg-red
                max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold"
          >
            SOME OF MY CREATIONS
          </div>
          <Project title="Project 1" />
          <Project title="Project 2" />

          {/* ROW 2 */}
          <Project title="Project 3" />
          <Project title="Project 4" />
          <Project title="Project 5" />

          {/* ROW 3 */}
          <Project title="Project 6" />
          <Project title="Project 7" />
          <div
            className="flex justify-center text-center items-center p-10 bg-blue
                max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold"
          >
            MORE PROJECTS ON THE WAY..
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
   

