import LineGradient from "../components/LineGradient"
import useMediaQuery from "../hooks/useMediaQuery"
import { motion } from "framer-motion"
import skillsImage from "../assets/skills-image.png"
const MySkills = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)")

return (
  <section 
    id="soft skills"
    className="pt-10 pb-24">
      <div className="md:flex md:justify-between md:gap-16 mt-16 md:mt-32">
        <motion.div
          className="md:w-1/3"
          initial ="hidden"
          whileInView = "visible"
          viewport = {{once:true, amount: 0.5 }}
          transition= {{duration :0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 }
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            MY <span className="text-yellow">SOFT</span> <span className="text-red">SKILLS</span>
          </p>
          <LineGradient  width="w-3/4" />
          <p className="mt-10 mb-7">
            Here's what i consider to be my Top 3 soft skills..
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveMediumScreens ? (
            <div 
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10 
              before:w-full before:h-full 
              before:border-2 before:border-blue before:z-[-1]">
                <img 
                src={skillsImage}
                alt="skills"
                className="z-10" />
            </div>
          ): (<img 
            src={skillsImage}
            alt="skills"
            className="z-10" />)}
        </div>
      </div>
      {/* SKILLS */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* CREATIVE */}
        <motion.div 
         className="md:w-1/3 mt-10"
         initial ="hidden"
         whileInView = "visible"
         viewport = {{once:true, amount: 0.5 }}
         transition= {{duration :0.5 }}
         variants={{
           hidden: { opacity: 0, y: 50 },
           visible: { opacity: 1, y: 0 }
         }}>
            <div className="relative h-32">
              <div className="z-10">
                <p className="font-playfair font-semibold text-5xl">01</p>
                <p className="font-playfair font-semibold text-3xl mt-3">
                  Creative
                </p>
              </div>
              <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
            </div>
            <p className="mt-5">
              "Creative isn't the way i think, it's the way i like to live". 
              I'm very comfortable thinking outside the box and coming with new and original ideas.
              I'm always looking for ways to improve things and that allowed me to develop my creative skills.
           </p>
         </motion.div>
         {/* RESILIENT */}
        <motion.div 
         className="md:w-1/3 mt-10"
         initial ="hidden"
         whileInView = "visible"
         viewport = {{once:true, amount: 0.5 }}
         transition= {{delay: 0.2, duration :0.5 }}
         variants={{
           hidden: { opacity: 0, y: 50 },
           visible: { opacity: 1, y: 0 }
         }}>
            <div className="relative h-32">
              <div className="z-10">
                <p className="font-playfair font-semibold text-5xl">02</p>
                <p className="font-playfair font-semibold text-3xl mt-3">
                  Resilient
                </p>
              </div>
              <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
            </div>
            <p className="mt-5">
              Resilience it's the ability to bounce back from difficulties. 
              In programming we are facing challanges and problems all the time, 
              we need to be able to overcome every obstacle that will come along the way.
              I've learned to make problem-solving as something fun to do. 
            </p>
         </motion.div>
         {/* RELENTLESS */}
        <motion.div 
         className="md:w-1/3 mt-10"
         initial ="hidden"
         whileInView = "visible"
         viewport = {{once:true, amount: 0.5 }}
         transition= {{delay : 0.4 ,duration :0.5 }}
         variants={{
           hidden: { opacity: 0, y: 50 },
           visible: { opacity: 1, y: 0 }
         }}>
            <div className="relative h-32">
              <div className="z-10">
                <p className="font-playfair font-semibold text-5xl">03</p>
                <p className="font-playfair font-semibold text-3xl mt-3">
                  Relentless
                </p>
              </div>
              <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
            </div>
            <p className="mt-5">
              I always liked the quote "Winners never quit and quitter never win". 
              I like to go all the way and get things done, no matter what. 
              That's what i consider the key for success. 
              Involves a lot of hard work and mental toughness, but it's always worthwhile.
            </p>
         </motion.div>
      
      </div>
  </section>
)
}

export default MySkills