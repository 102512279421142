import React from "react";
import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import EducationImage from "../assets/education.jpg";
import JavascriptEssentials from "../assets/javascript-essentials-1.png";
import MetaBackend from "../assets/meta-backend.png"
import Scrum from "../assets/scrum.JPG"
import { motion } from "framer-motion";

const Education = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
  return (
    <section
      id="education"
      className="md:flex md:justify-between md:gap-16 my-16 md:my-32 flex-col"
    >
      <div className="md:flex md:justify-between">
        <motion.div
          className="md:w-1/3 md:order-2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            EDU<span className="text-yellow">CA</span>
            <span className="text-red">TION</span>
          </p>
          <LineGradient width="w-1/2" />
          <p className="my-4">Official Courses and Certifications</p>
        </motion.div>
        <div className="mt-8 md:mt-8  md:mr-16">
          {isAboveMediumScreens ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10 
              before:w-full before:h-full 
              before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                src={EducationImage}
                alt="skills"
                className="z-10 w-[500px] h-[250px] object-cover "
              />
            </div>
          ) : (
            <img
              src={EducationImage}
              alt="skills"
              className="z-10 w-full h-[250px] object-cover"
            />
          )}
        </div>
      </div>
      {/* COURSES SECTION */}
      <div className="md:flex md:justify-center my-8 w-full">
        <div className="flex flex-col items-center w-full gap-4">
            <a
              href="https://www.credly.com/badges/be9544de-43c6-4610-92f8-125d1e335eba/public_url"
              target="_blank"
              className="w-full mx-auto sm:w-3/4"
            >
              <div className="flex items-center justify-around w-full gap-4 border-[1px] border-blue p-2">
                <div className="flex sm:basis-[80%] w-full gap-2">
                  <p className="text-l basis-1/2 cursor-pointer">
                    Javascript Essentials 1 </p> <span className="text-yellow text-l">Cisco Networking Academy </span>
                  
                </div>
                <div className="flex items-center">
                  <img
                    src={JavascriptEssentials}
                    alt="Javascript Essentials"
                    className="hidden sm:flex h-[80px] w-[80px] rounded-xl cursor-pointer"
                  />
                </div>
              </div>
            </a>
            <a href="https://drive.google.com/file/d/1LVYAnbhh7HRawkApZ7jniJM9K-z1a4GY/view?usp=drive_link" target="_blank" className="w-full sm:w-3/4 mx-auto">
              <div className="flex items-center justify-around w-full gap-4 border-[1px] border-blue p-2">
                <div className="flex sm:basis-[80%] w-full gap-2">
                  <p className="text-l basis-1/2 cursor-pointer ">
                   Agile Framework- SCRUM </p><span className="text-yellow text-l">Singular Desing E-Learning</span>
                  
                </div>
                <div className="flex items-end">
                  <img
                    src={Scrum}
                    alt="Javascript Essentials"
                    className="hidden sm:flex h-[80px] w-[80px] rounded-xl cursor-pointer"
                  />
                </div>
              </div>
            </a>      
          { /*  <a href="https://www.coursera.org/professional-certificates/meta-back-end-developer" target="_blank" className=" sm:w-3/4 w-full mx-auto ">
              <div className="flex items-center justify-around w-full  gap-4 border-[1px] border-blue p-2">
                <div className="flex sm:basis-[80%] w-full gap-2">
                  <p className="text-l cursor-pointer basis-1/2">
                   Meta Back-End Professional Developer (in process)</p> 
                   <span className="text-yellow text-l cursor-pointer">Meta Official Certification</span>
                
                </div>
                <div className="flex items-center">
                  <img
                    src={MetaBackend}
                    alt="Javascript Essentials"
                    className="hidden sm:flex h-[80px] w-[80px] rounded-xl cursor-pointer"
                  />
                </div>
              </div>
            </a> */}      
        </div>
      </div>
    </section>
  );
};

export default Education;
