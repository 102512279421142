import LineGradient from "../components/LineGradient"
import useMediaQuery from "../hooks/useMediaQuery"
import { motion } from "framer-motion"
import skillsImage from "../assets/hardskills.jpg"
import Wordpress from "../assets/wordpress.png"
import Figma from "../assets/figma.png";
import Reactlogo from "../assets/react.png"
import Redux from "../assets/redux.png"
import Tailwind from "../assets/tailwind.png"
import Mongo from "../assets/mongo.png"
import Node from "../assets/node.png"
import Js from "../assets/js.png"
import Ubuntu from "../assets/ubuntu.png"
import Socketio from "../assets/socketio.png"

const MySkills = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)")

return (
  <section 
    id="hard skills"
    className="pt-10 pb-24">
      <div className="md:flex md:justify-between md:gap-16 mt-16 md:mt-32">
        <motion.div
          className="md:w-1/3 md:order-2"
          initial ="hidden"
          whileInView = "visible"
          viewport = {{once:true, amount: 0.5 }}
          transition= {{duration :0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 }
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            MY <span className="text-yellow">HARD</span> <span className="text-red">SKILLS</span>
          </p>
          <LineGradient  width="w-3/4" />
          <p className="mt-10 mb-7">
            Technologies that i have worked with..
          </p>
        </motion.div>
          
        <div className="mt-16 md:mt-0">
          {isAboveMediumScreens ? (
            <div 
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10 
              before:w-full before:h-full 
              before:border-2 before:border-blue before:z-[-1]">
                <img 
                src={skillsImage}
                alt="skills"
                className="z-10" />
            </div>
          ): (<img 
            src={skillsImage}
            alt="skills"
            className="z-10" />)}
        </div>
      </div>
      {/* SKILLS */}
      <div className="md:flex md:justify-center mt-16 ">      
        <div className="grid place-items-center ss:grid-cols-5 xs:grid-cols-2 gap-x-16 gap-y-8">
          <img src={Reactlogo} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500 self-center"/>
          <img src={Js} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500" />
          <img src={Figma} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500"/>
          <img src={Wordpress} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500"/>
          <img src={Tailwind} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500"/>
          <img src={Mongo} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500" />        
          <img src={Node} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500"/>
          <img src={Redux} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500"/>
          <img src={Socketio} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500" />
          <img src={Ubuntu} alt="" className="h-[75px] grayscale hover:grayscale-0 transition duration-500"/>
        </div>                
      </div>
  </section>
)
}

export default MySkills